@use 'sass:math';
@import '@/styles/utils/mixins/breakpoints';

.sponsors {
  text-align: center;
  padding-top: var(--pbsk-grid-spacer);
  padding-bottom: var(--pbsk-grid-spacer);
}

.sponsorsTitle {
  font-family: var(--pbsk-font-family-sans-bold);
  font-size: rem-calc(14);
  text-transform: uppercase;
  padding-bottom: 16px;

  @include breakpoint(md) {
    font-size: rem-calc(18);
  }
}

@mixin sponsorGrid($sponsorWidth, $sponsorColumnGap, $sponsorRowGap) {
  max-width: #{3 * $sponsorWidth + 2 * $sponsorColumnGap};
  gap: $sponsorRowGap $sponsorColumnGap;

  li {
    width: $sponsorWidth;

    button img {
      width: auto;
      max-height: math.div($sponsorWidth, 2);
    }
  }
}

.sponsorsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-bottom: 60px;

  @include sponsorGrid(90px, 30px, 24px);

  li {
    position: relative;
  }

  @include breakpoint(md) {
    @include sponsorGrid(120px, 50px, 30px);
  }
}
