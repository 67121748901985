@import '@/styles/utils/mixins/breakpoints';
@import '@/styles/variables/breakpoints';
@import '@/styles/utils/functions/viewport-sizing';

.singleVideoModule {
  margin: 0 auto;
  width: setViewportWidth(280px, 320px);
  max-width: 335px;

  @include breakpoint(xs) {
    width: setViewportWidth(335px, $breakpoint-xs);
    max-width: 360px;
  }

  @include breakpoint(sm) {
    width: setViewportWidth(360px, $breakpoint-sm);
    max-width: 600px;
  }

  p:last-of-type {
    margin-bottom: 1em !important;
  }
}
