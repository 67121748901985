@import '@/styles/utils/functions/rem-calc';
@import '@/styles/utils/mixins/animations';
@import '@/styles/utils/mixins/breakpoints';

@mixin triangle($x-offset) {
  &::after {
    display: var(--triangle-display-mode, block);
    content: '';
    position: absolute;

    top: calc(var(--y-offset) + var(--y-offset-constant));
    transform: translate(50%, -100%);
    right: calc($x-offset + 50%);
    width: 0;
    height: 0;
    border-bottom: solid var(--triangle-size) white;
    border-left: solid var(--triangle-size) transparent;
    border-right: solid var(--triangle-size) transparent;
    filter: drop-shadow(0 -6px 4px rgb(0 0 0 / 5%));
  }
}

@mixin padTouchTarget() {
  // Fix Lighthouse failure for `tap-targets`
  display: inline-block;
  padding: calc(var(--pbsk-spacing-1) * 3.5);
  margin: 0 var(--pbsk-spacing-1);

  @include breakpoint(md) {
    padding: 0;
    margin: var(--pbsk-spacing-1) var(--pbsk-spacing-4) 0;
  }
}

.confirmationOuterWrapper {
  --y-offset-constant: 10px;

  --x-offset: 0%;
  --y-offset: 75%;

  --triangle-size: 12px;

  // Hiding on smaller screens now, by Design's request
  display: none;
  opacity: 0;
  font-size: rem-calc(15);

  @include standardTransition($properties: opacity);
  @include triangle($x-offset: var(--x-offset));

  &.isVisible {
    opacity: 1;
  }

  &.removeFromDomFlow {
    display: none;
  }

  &.modalOpen {
    --triangle-display-mode: none;

    @include breakpoint(sm) {
      --triangle-display-mode: block;
    }
  }

  @include breakpoint(md) {
    --triangle-size: 16px;

    display: block;
    font-size: rem-calc(13);
  }

  .confirmTitle {
    overflow-x: hidden;
    text-overflow: ellipsis;
    width: 100%;
    margin: 0;
    color: var(--pbsk-color-dark-gray);
    font-size: rem-calc(16);

    @include breakpoint(md) {
      font-size: rem-calc(14);
    }
  }

  .selectionButton {
    color: var(--pbsk-color-magenta);
    text-decoration: underline;
    font-weight: bold;

    @include padTouchTarget;
  }
}

.confirmationInnerWrapper {
  position: absolute;
  min-width: 100%; // it looks weird if the logo is wider than the popup, so this makes sure it's always at least as wide as the logo
  padding: var(--pbsk-spacing-2) var(--pbsk-spacing-3);

  max-width: calc(100vw - (var(--header-padding-horizontal) * 2));
  top: calc(var(--y-offset) + var(--y-offset-constant));
  right: var(--x-offset);

  text-align: center;
  background: #fff;
  color: var(--pbsk-color-dark-gray);
  box-shadow: 0 0 8px rgb(0 0 0 / 10%);

  .confirmationOuterWrapper.modalOpen & {
    padding: 0;
  }
}

.modalOverrideClass {
  position: fixed;

  @include breakpoint(sm) {
    position: static;
    transform: none;
  }
}
