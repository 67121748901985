// Defining breakpoint variables for responsive design as SASS variables because
// they get used in a couple places (variables/grid and mixins/breakpoints)
// these files refer to each other so these values have to be defined
// elsewhere in order to prevent circular references.

$breakpoint-xs: 375px;
$breakpoint-sm: 568px;
$breakpoint-md: 768px;
$max-lower-modules-width: 994px;
$max-lower-game-modules-width: 1128px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1280px;
$breakpoint-xxl: 1440px;
$max-screen-width: 1920px;

// Blurb, Header, and Copy modules
// should have a max width of about 60 characters.
$max-text-module-width: 60ch;
