@import '@/styles/utils/mixins/breakpoints';
@import '@/styles/utils/mixins/typography';
@import '@/styles/utils/functions/rem-calc';

.title {
  text-transform: uppercase;
  font-family: var(--pbsk-font-family-headline);
  color: var(--pbsk-theme-bodyTextColor);
  font-size: rem-calc(20) !important;
  padding-bottom: var(--pbsk-spacing-5);
  line-height: 1.2em;

  @include breakpoint(md) {
    font-size: rem-calc(26) !important;
  }

  @include breakpoint(lg) {
    font-size: rem-calc(32) !important;
  }

  @include breakpoint(xl) {
    @include text-md;
    padding-bottom: var(--pbsk-spacing-6);
  }

  &.emptyTitle {
    line-height: 1em;
    padding-bottom: 0;
  }
}


.grid {
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: var(--pbsk-spacing-4);

  @include breakpoint(sm) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: var(--pbsk-spacing-5);
  }

  @include breakpoint(lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: var(--pbsk-spacing-6);
  }

  @include breakpoint(xl) {
    gap: var(--pbsk-spacing-8);
  }

  &.poster, &.largeSquare, &.smallSquare {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @include breakpoint(sm) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

  &.smallSquare {
    grid-template-columns: repeat(3, minmax(0, 1fr));

    @include breakpoint(sm) {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
  }
}
