@use 'sass:math';
@import '@/styles/utils/mixins/aspect-ratio';
@import '@/styles/utils/mixins/breakpoints';


.character {
  position: absolute;
  bottom: 0;
  left: 25%;
  transform: translateX(-50%);

  transition-property: height, width;
  transition-duration: 150ms;
  transition-timing-function: ease-out;

  width: auto;
  max-width: unset;
  height: math.percentage(math.div(92, 72));

  @include breakpoint('only screen and (max-width: 767px)') {
    height: 100%;
  }
}

.backgroundContainer {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
  background-color: var(--pbsk-color-white);
  transition: box-shadow .2s ease;
}

.card {
  width: 168px;
  position: relative;

  @include aspect-ratio(168, 72);

  &:hover {
    .character {
      height: 140%;

      @include breakpoint('only screen and (max-width: 767px)') {
        height: 120%;
      }
    }
  }
}

.logo {
  bottom: 0;
  left: 0;
  z-index: 10;
}

.logo,
.background {
  position: absolute;
  width: auto;
  height: 100%;
}
