@use 'sass:math';
@import '@/styles/utils/functions/rem-calc';
@import '@/styles/utils/functions/viewport-sizing';
@import '@/styles/utils/mixins/animations';
@import '@/styles/utils/mixins/breakpoints';
@import '@/styles/utils/mixins/focus-visible';
@import '@/styles/utils/mixins/shadows';
@import '@/styles/utils/mixins/typography';


.mainContainer {
  height: 94px;
  display: flex;
  cursor: pointer;
  border-radius: 20px;

  @include standardTransition($properties: box-shadow);

  @include breakpoint(sm) {
    height: 87px;
  }

  @include breakpoint(lg) {
    height: 100px;
  }

  &:hover {
    @include box-shadow-border(var(--pbsk-theme-uiHighlightColor));
  }
}

.liveWrapper {
  width: setViewportWidth(100px, 568px);
  flex-shrink: 0;
  flex-grow: 0;

  // In the figma designs, the smallest width the live(magenta) box gets is 82px.
  // So this sets a min-width to ensure this doesn't shrink below that size. Same for the max.
  min-width: rem-calc(82);
  max-width: rem-calc(195);
  height: 100%;
  background-color: var(--pbsk-theme-calloutBackgroundColor);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px 0 0 20px;

  @include breakpoint(lg) {
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
  }

  span {
    font-family: var(--pbsk-font-family-headline);
    font-size: rem-calc(24);
    color: var(--pbsk-theme-calloutTextColor);
    text-transform: uppercase;

    @include breakpoint(lg) {
      font-size: rem-calc(38);
    }
  }
}

.liveLogo {
  width: 32px;
  fill: var(--pbsk-theme-calloutTextColor);
  flex: 0 0 content;

  @include breakpoint(lg) {
    flex-basis: initial;
    width: 51px;
  }
}

.currentShowWrapper {
  width: 100%;
  border-radius: 0 20px 20px 0;
  background-color: var(--pbsk-theme-uiControlColor);
  display: flex;
  justify-content: center;
  align-items: center;

  // 2% -> ~9px @ 368px width & ~19px @ 1024px width.
  gap: 2%;

  // Add 20% (20px) of the .liveWrapper width to the right-padding.
  padding: 0 calc(2% + setViewportWidth(20px, 568px)) 0 2%;

  @include breakpoint(sm) {
    justify-content: flex-start;
  }

  @include breakpoint(lg) {
    gap: var(--pbsk-spacing-5);
    padding: 0 var(--pbsk-spacing-5);
  }

  .characterCard {
    height: 90%;
    align-self: end;
    width: auto;

    @include breakpoint(xs) {
      height: 100%;
    }

    .characterBackground {
      background-color: unset;
      box-shadow: none !important; // no-box shadow on hover.
    }
  }
}

.logoWrapper {
  width: 70px;
  margin: 0 var(--pbsk-spacing-4);
  flex-shrink: 0;

  @include breakpoint(lg) {
    width: 80px;
    margin: 0 var(--pbsk-spacing-8);
  }
}

.brandLogo {
  width: 100%;
}

.textWrapper {
  display: none;
  width: 70%;
  align-items: center;
  justify-content: center;

  span {
    font-family: var(--pbsk-font-family-headline);
    font-size: rem-calc(16);
    color: var(--pbsk-theme-uiLabelColor);
    text-transform: uppercase;

    @include breakpoint(lg) {
      font-size: rem-calc(24);
    }
  }

  @include breakpoint(sm) {
    display: block;
  }

  p {
    color: var(--pbsk-theme-uiLabelColor);
    font-family: var(--pbsk-font-family-sans);
    margin-top: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: rem-calc(14);
    line-height: rem-calc(17.33);
    margin-bottom: 0;

    @include breakpoint(lg) {
      -webkit-line-clamp: 1;
    }
  }
}

.linkStyle {
  display: block;

  @include focus-visible {
    outline-color: var(--pbsk-theme-uiHighlightColor);
    outline-offset: var(--pbsk-spacing-2);
  }
}
