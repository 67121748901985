@import '@/styles/utils/mixins/breakpoints';
@import '@/styles/utils/mixins/typography';

.blurbModule {
  // On viewports less 568px, the spacing between the blurb module
  // and the module above it should be 20px instead of the default 30px gap.
  margin-top: -10px;

  @include breakpoint(sm) {
    margin-top: 0;
  }

  .copy {
    font-family: var(--pbsk-font-family-sans-bold);

    @include text-lg;
    text-align: center;
    color: var(--pbsk-theme-bodyTextColor);
    margin: auto;
    max-width: var(--pbsk-max-text-module-width);

    @include breakpoint(md) {
      width: 90%;
    }

    @include breakpoint(lg) {
      font-size: 22px;
      width: 75%;
    }
  }
}
