@import '@/styles/utils/mixins/animations';

@keyframes logoJellySquish {
  0% {
    transform: scale3d(1, 1, 1);
  }

  16% {
    transform: scale3d(1.05, .9, 1);
  }

  22% {
    transform: scale3d(1.1, .85, 1);
  }

  38% {
    transform: scale3d(1.1, 1, 1);
  }

  72% {
    transform: scale3d(.9, 1.1, 1);
  }
}

@keyframes popcornPbs {
  0% {
    transform: translate(0, 0) scale(1, 1);
  }

  40% {
    transform: translate(0, -10%);
  }

  88% {
    transform: translate(0, 0) scale(1.1, 0.9);
  }

  100% {
    transform: translate(0, 0) scale(1, 1);
  }
}

@keyframes popcornKids {
  0% {
    transform: translate(0, 0);
  }

  40% {
    transform: translate(0, -5%);
  }

  88% {
    transform: translate(0, 0) scale(1.06, 0.94);
  }

  100% {
    transform: translate(0, 0);
  }
}

.wrapper {
  svg {
    pointer-events: all;
  }

  @media (prefers-reduced-motion: no-preference) {
    &:global(.popcorn) {
      :global {
        .logo-jelly-squish {
          animation-duration: 500ms;
          animation-timing-function: cubic-bezier(0, 0.9, 0.7, 1);

          &:local {
            animation-name: logoJellySquish;
          }
        }

        .letters-group path {
          animation-iteration-count: 1;
        }

        .letters-pbs path {
          animation-duration: 310ms;
          animation-timing-function: cubic-bezier(0, 0.8, 0.8, 1.1);

          &:local {
            animation-name: popcornPbs;
          }
        }

        .letters-kids path {
          animation-duration: 300ms;
          animation-timing-function: cubic-bezier(0, 0.8, 0.8, 1.1);

          &:local {
            animation-name: popcornKids;
          }
        }

        .pbs-p {
          animation-delay: 150ms;
          transform-origin: bottom left;
        }

        .pbs-b {
          animation-delay: 220ms;
          transform-origin: center center;
        }

        .pbs-s {
          animation-delay: 300ms;
          transform-origin: bottom right;
        }

        .kids-k {
          animation-delay: 375ms;
          transform-origin: bottom left;
        }

        .kids-i {
          animation-delay: 450ms;
          transform-origin: bottom center;
        }

        .kids-d {
          animation-delay: 525ms;
          transform-origin: bottom center;
        }

        .kids-s {
          animation-delay: 600ms;
          transform-origin: bottom right;
        }
      }
    }
  }
}
