@import '@/styles/utils/mixins/animations';
@import '@/styles/utils/mixins/breakpoints';
@import '@/styles/utils/functions/viewport-sizing';
@import '@/styles/utils/functions/rem-calc';
@import '@/styles/utils/mixins/shadows';

.imageContainer {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  border-radius: 20px;
  overflow: hidden;

  width: 100%;
  max-width: 334px;

  @include standardTransition($properties: box-shadow);

  @include breakpoint(xs) {
    max-width: 538px;
  }

  @include breakpoint(sm) {
    max-width: unset;
  }

  @include breakpoint(lg) {
    border-radius: 28px;
  }

  &:hover {
    @include box-shadow-border(var(--pbsk-theme-uiHighlightColor));
  }
}

.inMasthead {
  margin-top: 0;
  margin-bottom: 54px;

  @include breakpoint(sm) {
    border-width: 8px;
  }

  @include breakpoint(xl) {
    border-width: 12px;
  }
}

.backgroundImages img {
  width: 100%;
  height: auto;
  display: block;
}

.backgroundGradient {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(233.16deg, rgba(0 0 0 / 0%) 31.8%, rgba(0 0 0 / 50%) 95.65%);
}

.messageContainer {
  position: absolute;
  left: var(--pbsk-spacing-8);
  bottom: var(--pbsk-spacing-6);
  display: flex;
  flex-direction: column;
  row-gap: var(--pbsk-spacing-2);
  width: 50%;

  @include breakpoint(lg) {
    left: var(--pbsk-spacing-9);
    bottom: 1.875rem;
    row-gap: var(--pbsk-spacing-4);
  }
}

.logoImage {
  position: relative;
  width: 110px;
  bottom: var(--pbsk-spacing-2);

  @include breakpoint(md) {
    bottom: var(--pbsk-spacing-4);
    width: 140px;
  }

  @include breakpoint(lg) {
    width: 180px;
  }
}

.upperText {
  font-size: rem-calc(26);
  color: var(--pbsk-color-white);
  font-weight: 700;
  line-height: rem-calc(28);
  font-family: var(--pbsk-font-family-sans);

  @include breakpoint(sm) {
    font-size: rem-calc(28);
  }

  @include breakpoint(md) {
    font-size: rem-calc(32);
    line-height: rem-calc(40);
  }

  @include breakpoint(lg) {
    font-size: rem-calc(36);
  }
}

.lowerText {
  font-size: rem-calc(16);
  font-family: var(--pbsk-font-family-sans-bold);
  color: var(--pbsk-color-white);

  @include breakpoint(sm) {
    font-size: rem-calc(18);
    line-height: rem-calc(16);
  }

  @include breakpoint(md) {
    font-size: rem-calc(22);
  }

  @include breakpoint(lg) {
    font-size: rem-calc(26);
  }
}
