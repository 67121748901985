@import '@/styles/utils/mixins/breakpoints';
@import '@/styles/utils/functions/rem-calc';

.outerWrapper {
  --header-padding-vertical: 12px;
  --header-padding-horizontal: var(--pbsk-margin);
  --kids-logo-margin-right: 10px;
  --nav-icon-margin-right: 8px;

  position: relative;
  width: 100%;
  max-width: var(--pbsk-max-screen-width);
  margin: 0 auto;
  padding: var(--header-padding-vertical) var(--header-padding-horizontal);
  white-space: nowrap;

  // The site header, when the show logo is present, will sometimes overlap content below it.
  // The header should always be on top of that content.
  z-index: 999;

  img {
    vertical-align: middle;
  }

  @include breakpoint(md) {
    --header-padding-vertical: 18px;
    padding-bottom: calc(30px + var(--header-padding-vertical));
  }

  @include breakpoint(lg) {
    padding-bottom: var(--header-padding-vertical);
  }
}

.innerWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.pbsKidsLogo {
  width: 48px;
  margin-right: var(--kids-logo-margin-right) !important;

  @include breakpoint(md) {
    --kids-logo-margin-right: 26px;
    width: 100px;
  }
}

.navIconButton {
  display: inline-block;
  vertical-align: middle;
  margin-right: var(--nav-icon-margin-right);

  @include breakpoint(md) {
    --kids-logo-margin-right: 20px;
    --nav-icon-margin-right: 20px;
  }

  @include breakpoint(xl) {
    --kids-logo-margin-right: 26px;
    --nav-icon-margin-right: 20px;
  }
}

@include breakpoint(lg) {
  .isTopLevelPage {
    --nav-button-size-override: 116px;

    .pbsKidsLogo {
      width: 150px;
    }

    .navIconButton {
      --kids-logo-margin-right: 26px;
      --nav-icon-margin-right: 26px;
      --nav-button-font-size-override: #{rem-calc(21)};
    }
  }
}

.stationLogoOuter {
  position: relative;
  display: block;
}

.stationLogoInner {
  display: inline-block;
  width: 120px;
  height: 40px;
  position: relative;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
  }

  @include breakpoint(md) {
    width: 240px;
    height: 80px;
  }

  @include breakpoint(lg) {
    .isTopLevelPage & {
      transform: translate(0, -32.5%);
    }
  }
}
