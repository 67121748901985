@import '@/styles/utils/mixins/aspect-ratio';
@import '@/styles/utils/mixins/breakpoints';

[data-has-masthead-content='true'][data-has-masthead-theme='true'] {
  .outerWrapper {
    position: relative;
    overflow: hidden;
    background-color: var(--pbsk-theme-bodyBackgroundColor);
  }

  .imageWrapper {
    width: 200%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -50%;
  }

  .image {
    --fishbowl-curve-multiplier: .7;
    --fishbowl-width: 50%;
    --fishbowl-height: calc(50% * var(--pbsk-theme-backgroundImage-aspectRatio) * var(--fishbowl-curve-multiplier));

    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

    width: 100%;
    height: calc(100% + 1px); // Sometimes the image scaling can cause a 1px gap at the top -- adding a pixel to the height fixes it

    object-fit: contain;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-image: var(--pbsk-theme-backgroundImage);

    @include aspect-ratio(null, null, var(--pbsk-theme-backgroundImage-aspectRatio));

    @include breakpoint(lg) {
      width: auto;

      clip-path: ellipse(
        var(--fishbowl-width)
        var(--fishbowl-height)
        at 50%
      );
    }
  }

  .contentWrapper {
    position: relative;
  }

  .hasPropertyLogo .contentWrapper {
    padding-top: var(--pbsk-spacing-10);
  }
}
