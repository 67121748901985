@import '@/styles/utils/mixins/breakpoints';
@import '@/styles/utils/mixins/sr-only';
@import '@/styles/utils/mixins/typography';
@import '@/styles/utils/functions/rem-calc';

.button {
  --nav-button-size: var(--nav-button-size-override, 40px);
  --nav-button-title-font-size: var(--nav-button-font-size-override, #{rem-calc(16)});

  display: flex;
  place-items: center;
  width: var(--nav-button-size);
  height: var(--nav-button-size);

  @include breakpoint(md) {
    --nav-button-size: var(--nav-button-size-override, 82px);
  }

  & > div { // click animation div
    width: var(--nav-button-size);
    height: var(--nav-button-size);
  }

  &[data-icon-name='games'] svg {
    width: 85%;
    height: 34%;

    @include breakpoint(md) {
      width: 90%;
      height: 27%;
    }
  }

  &[data-icon-name='videos'] svg {
    width: 65%;
    height: 65%;

    @include breakpoint(md) {
      width: 100%;
      height: 35%;
    }
  }
}

.buttonInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: var(--pbsk-theme-uiControlColor);
  border-radius: 50%;
  width: 100%;
  height: 100%;

  /* stylelint-disable-next-line no-descending-specificity */
  svg {
    fill: var(--pbsk-theme-uiLabelColor);
  }
}

.title {
  position: relative;

  @include breakpoint('only screen and (max-width: #{$breakpoint-md - 1})') {
    @include sr-only;
  }

  @include breakpoint(md) {
    display: block;
    font-family: var(--pbsk-font-family-headline);
    text-transform: uppercase;
    color: var(--pbsk-theme-uiLabelColor);
    font-size: var(--nav-button-title-font-size);
    line-height: 1;
    padding-top: var(--pbsk-spacing-1);
  }

  [data-icon-name='games'] & {
    top: .2em;
  }
}
