@import '@/styles/utils/mixins/breakpoints';

.propertyLogoWrapper {
  display: grid;
}

.propertyLogo {
  --property-logo-default-top-margin: 10px;
  --property-logo-width: 112px;
  --property-logo-height: 56px;
  --pbsk-loader-image-size: 92%;

  width: var(--property-logo-width);
  height: var(--property-logo-height);
  margin: var(--property-logo-default-top-margin) auto 0;
  position: relative;

  :global {
    .jellySquish {
      animation-delay: 850ms;
    }
  }

  a {
    display: block;
    width: var(--property-logo-width);
    height: var(--property-logo-height);
  }

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @include breakpoint(sm) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }

  @include breakpoint(md) {
    --property-logo-width: 240px;
    --property-logo-height: 120px;

    position: relative;
    top: 100%;

    margin: -24px 0 0;
    transform: translate(-50%, -50%);
  }

  @include breakpoint(lg) {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -32.5%);
  }
}
