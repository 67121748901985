@import '@/styles/utils/mixins/breakpoints';
@import '@/styles/utils/mixins/typography';

.heading {
  text-transform: uppercase;
  text-align: center;
  font-size: 26px;
  color: var(--pbsk-theme-bodyTextColor);
  line-height: 35px;

  @include breakpoint(sm) {
    font-size: 32px;
    line-height: 42px;
  }

  @include breakpoint(lg) {
    font-size: 3.75rem;
    line-height: 1;
  }
}

.body {
  text-align: center;
  color: var(--pbsk-theme-bodyTextColor);
  max-width: var(--pbsk-max-text-module-width);
  margin: 0 auto;

  @include text-sm;

  @include breakpoint(lg) {
    @include text-md;
  }

  p {
    margin-top: 10px;
    margin-bottom: 0;

    @include breakpoint(xs) {
      margin-top: var(--pbsk-spacing-2);
    }

    @include breakpoint(lg) {
      margin-top: var(--pbsk-spacing-3);
    }
  }
}
