@import '@/styles/utils/mixins/aspect-ratio';
@import '@/styles/utils/mixins/focus-visible';
@import '@/styles/utils/functions/viewport-sizing';
@import '@/styles/utils/functions/rem-calc';
@import '@/styles/utils/mixins/breakpoints';
@import '@/styles/utils/mixins/shadows';

.embla {
  --slide-size: 60px;
  --slide-spacing: 12px;
  --button-width: 42px;
  position: relative;
  width: calc(100vw - var(--pbsk-client-scrollbar-width));
  overflow: hidden;
  background-color: var(--pbsk-theme-featureBackgroundColor);

  @include breakpoint(sm) {
    --slide-size: 72px;
    --slide-spacing: 15px;
  }

  @include breakpoint(md) {
    --slide-size: 170px;
    --slide-spacing: 20px;
  }

  @include breakpoint(lg) {
    --button-width: 85px;
  }

  @include breakpoint(xxl) {
    --slide-size: calc((#{$breakpoint-xl} - (var(--slide-spacing) * 6)) / 7);
  }

  @include breakpoint('only screen and (max-width: 567px)') {
    padding-bottom: 42px;
  }
}

.emblaViewport {
  position: relative;
  max-width: calc(100% - (var(--slide-spacing) * 2));
  margin: 0 auto;
  overflow: hidden;

  @include breakpoint(xxl) {
    max-width: $breakpoint-xxl;
    overflow: hidden;
  }
}

.emblaContainer {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-top: 22px;

  @include breakpoint(sm) {
    margin-left: calc(var(--button-width));
  }

  @include breakpoint(lg) {
    margin-left: calc(var(--slide-size) / 2 + var(--slide-spacing) * 2);
  }

  @include breakpoint(xxl) {
    margin-left: 80px;
  }

  @include breakpoint('only screen and (max-width: 767px)') {
    margin-top: 10px;
  }
}

.emblaSlide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  margin-left: var(--slide-spacing);
  position: relative;
  list-style: none;
  opacity: .5;
  transition: opacity .2s ease;
  pointer-events: none;

  &.slideInView {
    opacity: 1;
    pointer-events: all;
  }

  a {
    display: block;
    outline: none;
  }
}

@mixin fadeAway {
  --xxl-fade-position: calc(var(--slide-size) / 3 * -1);

  @include breakpoint(lg) {
    content: '';
    height: 100%;
    width: calc(var(--slide-size) * 2);
    position: absolute;
    top: 0;
    background: linear-gradient(90deg, var(--pbsk-theme-featureBackgroundColor) 0%, var(--pbsk-theme-featureBackgroundColor) 35%, transparent 40%, transparent 100%);
    pointer-events: none;
    z-index: 1;
  }
}

.characterList {
  padding: var(--slide-spacing);
  box-sizing: content-box;

  &::before {
    left: 0;

    @include fadeAway;

    @include breakpoint(xxl) {
      left: var(--xxl-fade-position);
    }
  }

  &::after {
    transform: scaleX(-1);
    right: 0;

    @include fadeAway;

    @include breakpoint(xxl) {
      right: var(--xxl-fade-position);
    }
  }

  @include breakpoint(xxl) {
    padding: var(--slide-spacing) 0;
  }
}

// Keep this around for when we support themed backgrounds in this component
// [data-theme-context='module'] {
//   .embla {
//     --pbsk-theme-featureBackgroundColor: var(--pbsk-theme-bodyBackgroundColor);
//   }

//   .characterList {
//     background-image: var(--pbsk-theme-backgroundPattern);
//     background-position: 0 0;
//     background-size: var(--pbsk-theme-backgroundPattern-width, auto)
//       var(--pbsk-theme-backgroundPattern-height, auto);
//   }
// }
.characterCard {
  width: var(--slide-size);

  &:hover,
  a:focus & {
    .backgroundContainer {
      @include box-shadow-border(var(--pbsk-theme-featureHighlightColor));
    }
  }

  @include breakpoint('only screen and (max-width: 766px)') {
    .backgroundContainer {
      height: 72px;

      @include aspect-ratio(1, 1);
    }

    .character {
      left: 50%;
    }

    .characterLogo {
      display: none;
    }
  }

  @include breakpoint('only screen and (max-width: 567px)') {
    .backgroundContainer {
      height: 60px;
    }
  }
}

.navButton {
  position: absolute !important;
  bottom: var(--slide-spacing);
  width: var(--button-width);
  height: 82px;
  background-color: var(--pbsk-theme-uiControlColor);
  z-index: 1;
  --button-position: 0;

  @include breakpoint(lg) {
    bottom: unset;
    top: calc(50% + (var(--slide-spacing) / 2));
    transform: translateY(-50%);
    --button-position: 36px;
  }

  @include breakpoint(xxl) {
    --button-position: calc(50% + (#{$breakpoint-xl} / 2) + var(--slide-spacing));
  }

  @include breakpoint('only screen and (max-width: 567px)') {
    width: 80px;
    height: 30px;
    border-radius: 10px;
    top: unset;
    bottom: var(--slide-spacing);
    background-color: var(--pbsk-theme-uiControlColor);
    opacity: 1;
    --button-position: calc(50% - 85px);

    svg {
      width: 11px;
    }
  }
}

.navLeft {
  z-index: 1;
  left: var(--button-position);

  @include breakpoint(xxl) {
    left: unset;
    right: var(--button-position);
  }
}

.navRight {
  right: var(--button-position);

  @include breakpoint(xxl) {
    right: unset;
    left: var(--button-position);
  }
}
